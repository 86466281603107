import axios from "axios";
import find from "lodash/find";
import orderBy from "lodash/orderBy";
import { fetchGet } from "./serviceClient";
const queryString = require("query-string");

export const fetchSearchableProducts = async () => {
  return await fetchGet(
    `${process.env.NEXT_PUBLIC_NEXTJS_API_URL}/product/searchable-products`
  );
};

export const fetchAllProducts = async () => {

  let page = 1, rawProducts = [];
  try {

    while (page < 3) {
      const paramsString = queryString.stringify({
        consumer_key: process.env.WORDPRESS_CONSUMER_KEY,
        consumer_secret: process.env.WORDPRESS_CONSUMER_SECRET,
        per_page: 100,
        page,
        status: "publish"
      });

      const res = await fetch(
        `${process.env.NEXT_PUBLIC_WORDPRESS_URL}/wp-json/wc/v2/products?${paramsString}`
      );
      const productsBatch = await res.json();
      rawProducts = rawProducts.concat(productsBatch);
      page += 1;
    }

    rawProducts.forEach((product, index) => {
      const metaData = product.meta_data;
      const productRules = find(metaData, { key: "_product_rules" });
      const ruleValue = find(productRules.value, {
        rule_name: "doctorsPrice",
      });
      product.doctor_price = ruleValue.rule_value;
    });

    const sortedProducts = orderBy(
      rawProducts,
      [(product) => product.name.toLowerCase()],
      ["asc"]
    );

    return sortedProducts;
  } catch (err) {
    console.log(err.message);
    return;
  }
};

const resources = {};
const makeRequestCreator = () => {
  let cancel;
  return async (query) => {
    if (cancel) {
      // Cancel the previous request before making a new request
      cancel.cancel();
    }
    // Create a new CancelToken
    cancel = axios.CancelToken.source();
    try {
      if (resources[query]) {
        // Return result if it exists
        return resources[query];
      }
      const res = await axios(query, {
        cancelToken: cancel.token,
      });
      const result = res.data;
      // Store response
      resources[query] = result;
      return result;
    } catch (error) {
      if (axios.isCancel(error)) {
        // Handle if request was cancelled
        console.log("Request canceled", error.message);
      } else {
        // Handle usual errors
        console.log("Something went wrong: ", error.message);
      }
    }
  };
};

export const fetchProductsBySearchString = makeRequestCreator();

export const getIngredientsOfProduct = (product) => {
  const productIngredients = {};

  product.meta_data.forEach((meta) => {
    const splits = meta.key.split("_");
    if (splits.length === 3 && splits[0] === "Ingredient") {
      const ingredientKey = `${splits[0]}-${splits[1]}`;
      const field = splits[2];

      if (!productIngredients[ingredientKey]) {
        productIngredients[ingredientKey] = {};
      }

      productIngredients[ingredientKey][field] = meta.value;
    }
  });

  return Object.values(productIngredients);
};

export const getRelatedVideosOfProduct = (product) => {
  const videos = [];
  product.meta_data.forEach((meta) => {
    const splits = meta.key.split("_");
    if (splits.length === 3 && splits[0] === "video") {
      videos.push(meta.value);
    }
  });

  return videos;
};

export const getRelatedImageIds = (product) => {
  const imageIds = [];
  product.meta_data.forEach((meta) => {
    const splits = meta.key.split("_");
    if (splits.length === 3 && splits[0] === "image") {
      if (parseInt(meta.value)){
        imageIds.push(meta.value);
      }
    }
  });

  return imageIds;
};

export const getActionsOfProduct = (product) => {
  const productActions = [];
  product.meta_data.forEach((meta) => {
    const splits = meta.key.split("_");
    if (splits.length === 3 && splits[0] === "action") {
      productActions.push(meta.value);
    }
  });

  return productActions;
};

export const getImageData = async (imageId) => {
  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_EXTERNAL_WORDPRESS_URL}/wp-json/wp/v2/media/${imageId}`
    );
    const rawImageData = await response.json();
    return { sourceUrl: rawImageData.source_url };
  } catch (err) {
    console.log(err.message);
    return;
  }
};
